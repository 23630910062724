var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"seven wide column"},[_c('h3',{staticClass:"ui header"},[_vm._v(" Créer un projet à partir d'un modèle disponible: ")]),_c('div',{staticClass:"ui divided items"},[_vm._l((_vm.project_types),function(project){return _c('div',{key:project.slug,staticClass:"item"},[_c('div',{staticClass:"ui tiny image"},[_c('img',{attrs:{"src":project.thumbnail.includes('default')
                ? require('@/assets/img/default.png')
                : _vm.DJANGO_BASE_URL + project.thumbnail + _vm.refreshId()}})]),_c('div',{staticClass:"middle aligned content"},[_c('div',{staticClass:"description"},[_c('router-link',{attrs:{"to":{
                name: 'project_create_from',
                params: {
                  slug: project.slug,
                },
              }}},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('p',[_vm._v(_vm._s(project.description))]),_c('strong',[_vm._v("Projet "+_vm._s(project.moderation ? '' : 'non')+" modéré")])],1),_c('div',{staticClass:"meta"},[_c('span',{attrs:{"data-tooltip":"Délai avant archivage"}},[_vm._v(" "+_vm._s(project.archive_feature)+" "),_c('i',{staticClass:"box icon"})]),_c('span',{attrs:{"data-tooltip":"Délai avant suppression"}},[_vm._v(" "+_vm._s(project.archive_feature)+" "),_c('i',{staticClass:"trash alternate icon"})]),_c('span',{attrs:{"data-tooltip":"Date de création"}},[_vm._v(" "+_vm._s(project.created_on)+" "),_c('i',{staticClass:"calendar icon"})])]),_c('div',{staticClass:"meta"},[_c('span',{attrs:{"data-tooltip":"Visibilité des signalement publiés"}},[_vm._v(" "+_vm._s(project.access_level_pub_feature)+" "),_c('i',{staticClass:"eye icon"})]),_c('span',{attrs:{"data-tooltip":"Visibilité des signalement archivés"}},[_vm._v(" "+_vm._s(project.access_level_arch_feature)+" "),_c('i',{staticClass:"archive icon"})])])])])}),(!_vm.project_types || _vm.project_types.length === 0)?_c('span',[_vm._v("Aucun projet type n'est défini.")]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }