<template>
  <div class="row">
    <div class="seven wide column">
      <h3 class="ui header">
        Créer un projet à partir d'un modèle disponible:
      </h3>
      <div class="ui divided items">
        <div
          v-for="project in project_types"
          :key="project.slug"
          class="item"
        >
          <div class="ui tiny image">
            <img
              :src="
                project.thumbnail.includes('default')
                  ? require('@/assets/img/default.png')
                  : DJANGO_BASE_URL + project.thumbnail + refreshId()
              "
            >
          </div>
          <div class="middle aligned content">
            <div class="description">
              <router-link
                :to="{
                  name: 'project_create_from',
                  params: {
                    slug: project.slug,
                  },
                }"
              >
                {{ project.title }}
              </router-link>
              <p>{{ project.description }}</p>
              <strong>Projet {{ project.moderation ? '' : 'non' }} modéré</strong>
            </div>
            <div class="meta">
              <span data-tooltip="Délai avant archivage">
                {{ project.archive_feature }}&nbsp;<i class="box icon" />
              </span>
              <span data-tooltip="Délai avant suppression">
                {{ project.archive_feature }}&nbsp;<i
                  class="trash alternate icon"
                />
              </span>
              <span data-tooltip="Date de création">
                {{ project.created_on }}&nbsp;<i class="calendar icon" />
              </span>
            </div>
            <div class="meta">
              <span data-tooltip="Visibilité des signalement publiés">
                {{ project.access_level_pub_feature }}&nbsp;<i
                  class="eye icon"
                />
              </span>
              <span data-tooltip="Visibilité des signalement archivés">
                {{ project.access_level_arch_feature }}&nbsp;<i
                  class="archive icon"
                />
              </span>
            </div>
          </div>
        </div>
        <span
          v-if="!project_types || project_types.length === 0"
        >Aucun projet type n'est défini.</span>
      </div>
    </div>
  </div>
</template>

<script>
import projectAPI from '@/services/project-api';

export default {
  name: 'ProjectTypeList',

  data() {
    return {
      project_types: null,
    };
  },

  computed: {
    DJANGO_BASE_URL: function () {
      return this.$store.state.configuration.VUE_APP_DJANGO_BASE;
    },
    API_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_API_BASE;
    },
  },

  mounted() {
    projectAPI.getProjectTypes(this.API_BASE_URL)
      .then((data) => {
        if (data) this.project_types = data;
      });
  },

  methods: {
    refreshId() {
      return '?ver=' + Math.random();
    },
  },
};
</script>